import Alpine from "alpinejs";
export default function () {
  Alpine.store('selectable', {
    //activate
    activate() {
      this.selectable = new Selectable({
        filter: '.selectable',
        appendTo: document.getElementById('thumb-wrap'),
        ignore: '.media-item',
        toggle: true,
      });
      this.selectable.on('end', (item) => {
        this.selected = this.selectable.getSelectedNodes();
        this.selected.forEach(el => Alpine.$data(el).selected = true);
      });
      this.selectable.on('deselecteditem', (item) => {
        this.selected = this.selectable.getSelectedNodes();
        this.selected.forEach(el => Alpine.$data(el).selected = false);
      });
      this.dispatchEvents(true);
    },
    //dispatchEvents
    dispatchEvents(active) {

      var stateEvent = new CustomEvent("selectable-state", {
        detail: {
          active: active
        }
      });

      var sharedStateEvent = new CustomEvent("shared-state", {
        detail: {
          active: active
        }
      });
      this.state = active;
      dispatchEvent(stateEvent);
      dispatchEvent(sharedStateEvent);

    },
    //# deselectAll
    deselectAll() {
      this.selected.forEach(el => {
        this.selectable.deselect(el);
      });
    },
    //# deselect
    deselect(el) {
      this.selectable.deselect(el);
    },
    //# destroy
    destroy() {
      this.selected = [];
      this?.selectable?.destroy();
      this.selectable = null;
      try {
        this.dispatchEvents(false);
        Livewire.emitTo('media-library.file-view', 'updateView');
      } catch (ex) {

      }

    },
    //# addSelected
    addSelected() {
      var ids = this.selected.map(e => Alpine.$data(e).mediaId);
      Livewire.emitTo('add-edit.group', 'addMedia' + Alpine.store("sidebar").groupId, ids);
      this.deselectAll();
    },
    //# deleteSelected
    deleteSelected() {
      var prompt = confirm('This will delete all selected items.  Proceed?');
      if (!prompt) {
        return;
      }
      this.selected.forEach(el => {
        var data = Alpine.$data(el);
        data.exists = false;
        this.deselect(el);
        setTimeout(() => {
          data.deleteItem();
        }, 350);
      });
      this.destroy();

    },
    selected: [],

    selectable: null,
    state: false
  });
}
