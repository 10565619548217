import Alpine from "alpinejs";

export default function () {
  Alpine.store('sidebar', {
    active: false,
    groupId: '',
    view: '',
    close() {
      this.active = false;
      this.view = null;
      this.groupId = null;
    },
    open(args) {
      this.active = true;
      this.view = args?.view;
      this.groupId = args?.groupId;
    },
    toggle() {
      if (this.active) {
        return this.close();
      }
      return this.open(arguments);
    }
  });
}