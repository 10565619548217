// test
import collapse from "@alpinejs/collapse";
import persist from '@alpinejs/persist';
import focus from '@alpinejs/focus';
import Alpine from "alpinejs";
import stores from "./alpine/_stores";
import abandon from "./alpine/_abandon";

stores();
Alpine.plugin(abandon);
Alpine.plugin(collapse);
Alpine.plugin(persist);
Alpine.plugin(focus);
window.Alpine = Alpine;
Alpine.start();

